import Socket = SocketIOClient.Socket;
import { fromEvent, Subscription, Observable } from "rxjs";

export default class WebSocketService {
  private readonly activeSubscriptions: { [key: string]: Subscription[] };
  private readonly sources$: {
    [key: string]: { observable: Observable<any>; event: string }[];
  };
  private static _instance: WebSocketService;
  private socketInstances: Socket[];

  private sessionDuplicatedAlert: boolean;
  private sessionReconnectedAlert: boolean;
  private totallyDisconnectedAlert: boolean;

  private disconnectedInterval: any;
  private timeToDisconnect: number;

  public disconnectedTimeout?: number;

  public static instance(): WebSocketService {
    return this._instance || (this._instance = new this());
  }

  private constructor() {
    this.socketInstances = [];
    this.activeSubscriptions = {};
    this.sources$ = {};
    this.sessionDuplicatedAlert = false;
    this.sessionReconnectedAlert = false;
    this.totallyDisconnectedAlert = false;
    this.timeToDisconnect = 0;
  }

  get sockets(): Socket[] {
    return this.socketInstances;
  }

  get disconnectedStatus(): number {
    return this.timeToDisconnect;
  }

  get isSessionDuplicated(): boolean {
    return this.sessionDuplicatedAlert;
  }

  get isSessionReconnected(): boolean {
    return this.sessionReconnectedAlert;
  }

  get isSessionTotallyDisconnected(): boolean {
    return this.totallyDisconnectedAlert;
  }

  get actualSocketInstances(): Socket[] {
    return this.socketInstances;
  }

  public newSocketInstance(socket: Socket): void {
    this.socketInstances.push(socket);
  }

  public deleteSocketInstance(socket: Socket): void {
    const socketEncountered: Socket | undefined = this.socketInstances.find(
      (tempSocket) => tempSocket === socket
    );
    if (socketEncountered) {
      this.activeSubscriptions[socketEncountered.nsp].forEach(
        (subscription: Subscription) => subscription.unsubscribe()
      );
      delete this.activeSubscriptions[socketEncountered.nsp];
      delete this.sources$[socketEncountered.nsp];
      this.socketInstances.splice(
        this.socketInstances.indexOf(socketEncountered),
        1
      );
      if (socketEncountered.connected) {
        socketEncountered.disconnect();
      }
    }
  }

  public listen(
    nsp: string,
    event: string,
    callback: (data: any) => void
  ): Subscription {
    const socket: any = this.socketInstances.find(
      (tempSocket: Socket) => tempSocket.nsp === nsp
    );

    if (!this.sources$[nsp]) {
      this.sources$[nsp] = [];
    }
    if (!this.activeSubscriptions[nsp]) {
      this.activeSubscriptions[nsp] = [];
    }
    let source$: Observable<any>;
    const existingSource = this.sources$[nsp].find(
      (element) => element.event === event
    );
    if (!existingSource) {
      source$ = fromEvent(socket, event);
      this.sources$[nsp].push({ observable: source$, event });
    } else {
      source$ = existingSource.observable;
    }
    const subscription = source$.subscribe(callback);
    this.activeSubscriptions[nsp].push(subscription);
    return subscription;
  }

  public emit(
    nsp: string,
    event: string,
    payload: any,
    callback?: () => void
  ): void {
    const socket: any = this.socketInstances.find(
      (tempSocket: Socket) => tempSocket.nsp === nsp
    );
    if (callback) {
      socket.emit(event, payload, callback);
    } else {
      socket.emit(event, payload);
    }
  }

  public deleteAllSocketInstances(): void {
    this.socketInstances.forEach((socket: Socket) =>
      this.deleteSocketInstance(socket)
    );
  }

  public setDisconnectedTimeout(timeout: number, callback: () => void): void {
    this.sessionReconnectedAlert = false;
    this.timeToDisconnect = timeout;
    this.disconnectedInterval = setInterval(() => {
      this.timeToDisconnect = (this.timeToDisconnect as any) - 1000;
      if (this.timeToDisconnect < 0) {
        this.clearDisconnectedTimeout();
      }
    }, 1000);
    this.disconnectedTimeout = setTimeout(callback, timeout);
  }

  public clearDisconnectedTimeout(): void {
    this.timeToDisconnect = 0;
    clearInterval(this.disconnectedInterval);
    clearTimeout(this.disconnectedTimeout);
    this.disconnectedTimeout = undefined;
  }

  public triggerDuplicatedSessionAlert(): void {
    this.sessionDuplicatedAlert = true;
    setTimeout(() => (this.sessionDuplicatedAlert = false), 4000);
  }

  public triggerReconnectedSessionAlert(): void {
    this.sessionReconnectedAlert = true;
    setTimeout(() => (this.sessionReconnectedAlert = false), 4000);
  }

  public triggerTotallyDisconnectedAlert(): void {
    this.totallyDisconnectedAlert = true;
    setTimeout(() => (this.totallyDisconnectedAlert = false), 4000);
  }
}

export interface Conversation {
  currentAgent: string;
  currentChannel: string;
  currentConsumer: string;
  consumer?: string;
  currentInputInteraction: any[];
  currentTopic: string;
  interactions: ConversationMessage[];
  _id: string;
  newMessage: number;
  isClosed?: boolean;
  variables: any;
  contactData: ContactData;
  newinteractions?: number;
  updatedAt?: string;
  createdAt?: string;
  project?: string;
}

export interface BusinessStatus {
  name: string;
  description: string;
  color: string;
  message: string;
  finish: boolean;
}

export interface returnOldConversation {
  _id?: string;
  openConversations: string[];
}

export interface ContactData {
  name?: string;
  lastName?: string;
  phoneNumber?: string;
  address?: string;
  city?: string;
  email?: string;
  identification?: string;
  postalCode?: string;
}

export enum MessageType {
  //input
  TEXT = "TEXT",
  BOOLEAN = "BOOLEAN",
  OPTION = "OPTION",

  //output
  MESSAGE = "MESSAGE",
  FILE = "FILE",
  AUDIO = "AUDIO",
  IMAGE = "IMAGE",
  LINK = "LINK",
  END = "END",
}

export enum InteractionOrigin {
  REF = "REF",
  FRW = "FRW",
  RSP = "RSP",
}

interface IOption {
  label: string;
  value: string;
}

interface SetVariable {
  name: string;
  label: string;
}

export interface ConversationMessageElement {
  id: string;
  type: MessageType;
  init?: boolean;
  skip?: boolean;
  url?: string;
  fileurl?: string;
  mimetype?: string;
  filename?: string;
  text?: string[] | any;
  link?: string;
  title?: string;
  success?: string;
  failure?: string;
  options?: IOption[];
  resetValue?: boolean;
  validationMessage?: string;
  setVariable?: SetVariable;
  origin: string;
  referenceId?: string;
}

export interface ConversationMessageArray {
  id: string;
  interactions: ConversationMessage[];
}

export interface ConversationMessage {
  conversation: string;
  output: ConversationMessageElement[];
  input: ConversationMessageElement[];
}

export interface ConversationNewEntryQueue {
  _id?: string;
  channel: string;
  consumer: string;
  currentTopic?: string;
  conversation: Conversation;
  topic: string;
}

/**Conversation State */
export interface ConversationState {
  loading: boolean;
  conversationReturn: Conversation | null;
  error: any;
  currentConversation: Conversation;
}

export interface IConversation {
  _id: string;
  subscriptions: string[];
}

export interface IError {
  code: string;
  _id: string;
  title: string;
}

export type ExtendedConversationState = { conversation?: ConversationState };

export const initialConversationState: ConversationState = {
  loading: false,
  conversationReturn: null,
  error: {},
  currentConversation: <Conversation>{},
};

export interface Interaction {
  _id: string;
  text: string[];
  mode: string;
  type: string;
  conversation: string;
  agent: string;
  fileurl?: string;
  filename: string;
  consumer: string;
  channel: string;
  createdAt: Date;
  updatedAt: Date;
  delivered?: boolean;
  origin: InteractionOrigin;
  referenceId?: string;
  status?: string;
  _errors?: IError[];
}

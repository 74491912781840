import { DefineTypes, DefineActions } from "@/app/store/store.helper";
import { RootState } from "@/app/store/root.models";
import { AgentState, IAgent, SOCKET_EVENTS } from "./agent.models";
import { UserState } from "../user/user.models";
import Axios from "axios";
import { BASE_URL_MANAGER, BASE_URL_ORCHESTATOR } from "@/config";
import { agentMutationsTypes } from "./agent.mutations";
import SocketIO from "socket.io-client";
import {
  ConversationNewEntryQueue,
  Conversation,
} from "@/app/store/modules/conversation/conversation.models";
import { conversationMutationsTypes } from "../conversation/conversation.mutations";
import { store } from "@/app/store";
import Socket = SocketIOClient.Socket;
import WebSocketService from "@/app/services/web-socket";
import { authActionsTypes } from "@/app/store/modules/auth/auth.actions";

export interface AgentActions {
  loadAgent: UserState["user"]["_id"];
  loadAgentOpenConversations: {
    agent_id: string;
    page?: number;
    limit?: number;
  };
  loadAgentCloseConversations: { agent_id: string; page?: number };
  loadAgentQueues: {
    subscriptions: AgentState["agent"]["subscriptions"];
    page?: number;
    restartQueue?: boolean;
  };
  playSound: string;
  loadActualInteractions: { conversationId: string; page: number };
}
const NEW_MESSAGE_SOUND = "http://soundbible.com/grab.php?id=1645&type=mp3";
const NEW_CONVERSATION_SOUND =
  "https://www.ringtina.com.ar/Descargar/Ringtones/Notificaciones/Tejat.mp3?_=5";
export const agentActionsTypes: DefineTypes<AgentActions> = {
  loadAgent: (payload) => ({ type: "loadAgent", payload }),
  loadAgentOpenConversations: (payload) => ({
    type: "loadAgentOpenConversations",
    payload,
  }),
  loadAgentCloseConversations: (payload) => ({
    type: "loadAgentCloseConversations",
    payload,
  }),
  loadAgentQueues: (payload) => ({
    type: "loadAgentQueues",
    payload,
  }),
  playSound: (payload) => ({
    type: "playSound",
    payload,
  }),
  loadActualInteractions: (payload) => ({
    type: "loadActualInteractions",
    payload,
  }),
};

const actions: DefineActions<AgentActions, AgentState, RootState> = {
  async loadAgent({ commit, dispatch, rootState }, { payload }) {
    // commit(agentMutationsTypes.setLoading(true));
    const url = `${BASE_URL_MANAGER}/user/${payload}/agent`;

    Axios(url)
      .then(async (response) => {
        const { agent } = <{ agent: IAgent }>response.data;
        if (agent) {
          commit(agentMutationsTypes.resetAgent());
          commit(agentMutationsTypes.setAgent(agent));

          const webSocketService: WebSocketService =
            WebSocketService.instance();

          agent.subscriptions.forEach((subscription) => {
            const tempSocket = webSocketService.sockets.find(
              (socket: Socket) => socket.nsp === `/${subscription}`
            );
            if (tempSocket) {
              webSocketService.deleteSocketInstance(tempSocket);
            }

            const socket: Socket = SocketIO(
              `${BASE_URL_ORCHESTATOR}/${subscription}`
            );
            webSocketService.newSocketInstance(socket);

            webSocketService.listen(socket.nsp, "connect", () => {
              webSocketService.emit(socket.nsp, SOCKET_EVENTS.AGENT, agent._id);
              if (webSocketService.disconnectedStatus > 0) {
                webSocketService.triggerReconnectedSessionAlert();
                webSocketService.clearDisconnectedTimeout();
                dispatch(agentActionsTypes.loadAgent(payload));
              }
            });

            webSocketService.listen(socket.nsp, "disconnect", () => {
              if (!webSocketService.disconnectedTimeout) {
                webSocketService.setDisconnectedTimeout(90000, () =>
                  dispatch(authActionsTypes.logout())
                );
              }
            });

            webSocketService.listen(
              socket.nsp,
              SOCKET_EVENTS.DUPLICATED_SESSION,
              () => {
                webSocketService.deleteAllSocketInstances();
                webSocketService.triggerDuplicatedSessionAlert();
                dispatch(authActionsTypes.logout());
              }
            );

            webSocketService.listen(
              socket.nsp,
              SOCKET_EVENTS.TRANSFERRED_CONVERSATION,
              ({
                agent,
                conversation,
              }: {
                agent: IAgent;
                conversation: Conversation;
              }) => {
                commit(
                  agentMutationsTypes.setAgentOpenConversations([conversation])
                );
                commit(
                  agentMutationsTypes.setAgentTransferredConversation({
                    agent,
                    conversation,
                  })
                );
              }
            );

            webSocketService.listen(
              socket.nsp,
              SOCKET_EVENTS.NEW_ENTRY,
              (entry: ConversationNewEntryQueue) => {
                dispatch(agentActionsTypes.playSound(NEW_CONVERSATION_SOUND));

                commit(agentMutationsTypes.setNewEntryInQueue(entry));
                commit(
                  agentMutationsTypes.setQueueTotal(
                    rootState.agent!.agentQueueTotal + 1
                  )
                );
              }
            );

            webSocketService.listen(
              socket.nsp,
              SOCKET_EVENTS.AWATING_RESPONSE,
              (entry: any) => {
                dispatch(agentActionsTypes.playSound(NEW_CONVERSATION_SOUND));
                if (
                  !rootState.conversation!.currentConversation ||
                  !rootState.conversation!.currentConversation._id ||
                  rootState.conversation!.currentConversation._id === entry._id
                ) {
                  commit(
                    conversationMutationsTypes.setCurrentConversation(entry)
                  );
                }
                commit(conversationMutationsTypes.setConversationReturn(entry));
                commit(agentMutationsTypes.removeConversationClosed(entry._id));
                commit(agentMutationsTypes.setAgentOpenConversations([entry]));
              }
            );

            webSocketService.listen(
              socket.nsp,
              SOCKET_EVENTS.REMOVE_ENTRY,
              (entry: ConversationNewEntryQueue) => {
                commit(agentMutationsTypes.setRemoveEntryOfQueue(entry));
              }
            );

            webSocketService.listen(
              socket.nsp,
              SOCKET_EVENTS.NEW_MESSAGE,
              (entry: any) => {
                if (entry.input.agent === store.state.agent!.agent._id) {
                  dispatch(agentActionsTypes.playSound(NEW_MESSAGE_SOUND));
                }
                if (
                  !rootState.conversation!.currentConversation ||
                  !rootState.conversation!.currentConversation._id ||
                  rootState.conversation!.currentConversation._id !==
                    entry.conversation
                ) {
                  commit(
                    agentMutationsTypes.setMessageInOpenConversation(entry)
                  );
                }
                if (
                  rootState.conversation!.currentConversation &&
                  rootState.conversation!.currentConversation._id &&
                  rootState.conversation!.currentConversation._id ===
                    entry.conversation
                ) {
                  commit(
                    agentMutationsTypes.setMessageInOpenConversation(entry)
                  );
                  commit(
                    conversationMutationsTypes.setMessageInConversation(entry)
                  );
                  commit(agentMutationsTypes.setNewInteraction(entry.input));
                }
              }
            );

            webSocketService.listen(
              socket.nsp,
              SOCKET_EVENTS.DELETE_CONVERSATION,
              (entry: ConversationNewEntryQueue) => {
                if (
                  rootState.conversation!.currentConversation &&
                  rootState.conversation!.currentConversation._id &&
                  rootState.conversation!.currentConversation._id ===
                    entry.conversation._id
                ) {
                  commit(
                    agentMutationsTypes.removeConversationOpen(
                      entry.conversation._id
                    )
                  );
                  commit(
                    agentMutationsTypes.setCurrentConversationInConversations(
                      <Conversation>{}
                    )
                  );
                  commit(
                    conversationMutationsTypes.setCurrentConversation(
                      <Conversation>{}
                    )
                  );
                  commit(
                    conversationMutationsTypes.setError({
                      message: "Se ha perdido la comunicación con el cliente",
                      visibleError: true,
                    })
                  );
                  commit(agentMutationsTypes.setLoading(true));
                  setInterval(
                    () => commit(agentMutationsTypes.setLoading(false)),
                    500
                  );
                }
                if (
                  !rootState.conversation!.currentConversation ||
                  !rootState.conversation!.currentConversation._id ||
                  rootState.conversation!.currentConversation._id !==
                    entry.conversation._id
                ) {
                  commit(agentMutationsTypes.setRemoveEntryOfQueue(entry));
                }
              }
            );

            // listen changes when an interactions is changed
            webSocketService.listen(
              socket.nsp,
              SOCKET_EVENTS.UPDATE_INTERACTION,
              (entry: any) => {
                commit(agentMutationsTypes.setUpdateInteraction(entry));
              }
            );
          });

          await dispatch(
            agentActionsTypes.loadAgentOpenConversations({
              agent_id: agent._id,
              page: 0,
              limit: 50,
            })
          ).catch(() => {
            // webSocketService.triggerTotallyDisconnectedAlert();
            // dispatch(authActionsTypes.logout())
          });
          await dispatch(
            agentActionsTypes.loadAgentCloseConversations({
              agent_id: agent._id,
              page: 0,
            })
          ).catch(() => {
            //  webSocketService.triggerTotallyDisconnectedAlert();
            // dispatch(authActionsTypes.logout())
          });
          await dispatch(
            agentActionsTypes.loadAgentQueues({
              subscriptions: agent!.subscriptions,
              page: 0,
            })
          ).catch(() => {
            // webSocketService.triggerTotallyDisconnectedAlert();
            //dispatch(authActionsTypes.logout())
          });
        }
      })
      .finally(() => commit(agentMutationsTypes.setLoading(false)));
  },
  async playSound({}, { payload }) {
    let audio = new Audio(payload);
    await audio.play();
    return 1;
  },
  async loadAgentOpenConversations({ commit }, { payload }) {
    const response = await Axios(
      `${BASE_URL_ORCHESTATOR}/agent/${payload.agent_id}/conversation/open`,
      {
        params: {
          limit: payload!.limit,
          page: payload!.page,
        },
      }
    );
    const { conversations, total } = response.data;

    if (Array.isArray(conversations)) {
      commit(agentMutationsTypes.setAgentOpenConversations(conversations));
      commit(agentMutationsTypes.setAgentOpenTotalConversations(total));
    }
  },
  async loadAgentCloseConversations({ commit }, { payload }) {
    const response = await Axios(
      `${BASE_URL_ORCHESTATOR}/agent/${payload.agent_id}/conversation/close`,
      {
        params: {
          page: payload!.page,
        },
      }
    );
    const { conversations, total } = response.data;
    if (Array.isArray(conversations)) {
      commit(agentMutationsTypes.setAgentCloseConversations(conversations));
      commit(agentMutationsTypes.setAgenCloseTotalConversations(total));
    }
  },
  async loadAgentQueues({ commit }, { payload }) {
    const response = await Axios(`${BASE_URL_MANAGER}/queue`, {
      params: {
        subscriptions: payload.subscriptions,
        page: payload!.page,
      },
    });
    const { queues, total } = response.data;

    if (payload!.restartQueue === true) {
      commit(agentMutationsTypes.resetQueue([]));
      commit(agentMutationsTypes.setQueueTotal(0));
    }
    if (Array.isArray(queues) && queues.length) {
      commit(agentMutationsTypes.setQueue(queues));
      commit(agentMutationsTypes.setQueueTotal(total));
    }
  },
  async loadActualInteractions({ commit }, { payload }) {
    console.log("loadActualInteractions");

    const response = await Axios(
      `${BASE_URL_ORCHESTATOR}/conversation/convesations/${payload.conversationId}/${payload.page}`
    );
    if (payload.page === 0) {
      commit(
        agentMutationsTypes.setInteractions(
          response.data.interactions.reverse()
        )
      );
      commit(agentMutationsTypes.setTotalPages(response.data.pagecount));
    } else {
      commit(
        agentMutationsTypes.loadMoreInteractions(response.data.interactions)
      );
    }
    commit(agentMutationsTypes.setActualPage(Number(response.data.pagenum)));
  },
};

export default actions;

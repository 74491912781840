import { DefineTypes, DefineActions } from "@/app/store/store.helper";
import { RootState } from "@/app/store/root.models";
import Axios from "axios";
import { BASE_URL_MANAGER } from "@/config";
import router from "@/app/router";
import { UserState, UserRole, IUser } from "./user.models";
import { userMutationsTypes } from "./user.mutations";
import { agentActionsTypes } from "../agent/agent.actions";
import { authActionsTypes } from "../auth/auth.actions";

export interface UserActions {
    loadUser: UserState["user"]["_id"];
    dispatchRouteByRole: Pick<UserState["user"], "_id" | "role">;
}

export const userActionsTypes: DefineTypes<UserActions> = {
    loadUser: payload => ({ type: "loadUser", payload }),
    dispatchRouteByRole: payload => ({ type: "dispatchRouteByRole", payload }),
};

const actions: DefineActions<UserActions, UserState, RootState> = {
    async loadUser({ commit, dispatch }, { payload }) {
        commit(userMutationsTypes.setLoading(true));
        await Axios(`${BASE_URL_MANAGER}/user/${payload}`)
            .then(async resp => {
                if (resp != undefined) {
                    const { user } = <{ user: IUser }>resp.data;
                    commit(userMutationsTypes.setLoading(false));

                    if (!user) {
                        console.log(user);
                        await router.push({ name: "login" });
                        commit(userMutationsTypes.setUser({ _id: "", login: false }));
                        await dispatch(authActionsTypes.setToken(""));
                        return;
                    }
                    commit(userMutationsTypes.setUser({ ...user, login: true, }));
                    await dispatch(userActionsTypes.dispatchRouteByRole(user));
                } else {
                    commit(userMutationsTypes.setUser({ _id: "", login: false }));
                    await dispatch(authActionsTypes.setToken(""));
                    await router.push({ name: "login" });
                }
            })
            .catch(async () => {
                commit(userMutationsTypes.setUser({ _id: "", login: false }));
                await dispatch(authActionsTypes.setToken(""));
                await router.push({ name: "login" });
            })
            .finally(() => commit(userMutationsTypes.setLoading(false)));
    },
    async dispatchRouteByRole({ dispatch }, { payload }) {
        if (payload.role === UserRole.AGENT) {
            await router.push({ name: "agent" });
            
            await dispatch(agentActionsTypes.loadAgent(payload._id));
        } else if (payload.role === UserRole.ADMIN || payload.role === UserRole.BASE) {
            await router.push({ name: "admin" });
        
        } else {
            await router.push({ name: "home" });
            
        }
    },
};

export default actions;

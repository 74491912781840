import { DefineTypes, DefineMutations } from "@/app/store/store.helper";
import { RootState } from "@/app/store/root.models";
import { UserRole, UserState } from "./user.models";

export interface UserMutations {
    setUser: UserState["user"];
    setLoading: UserState["loading"];
}

export const userMutationsTypes: DefineTypes<UserMutations> = {
    setUser: payload => ({ type: "setUser", payload }),
    setLoading: payload => ({ type: "setLoading", payload }),
};

const mutations: DefineMutations<UserMutations, UserState, RootState> = {
    setUser(state, { payload }) {
        state.user = { ...payload, role: payload.role || UserRole.BASE };
    
    },
    setLoading(state, { payload }) {
        state.loading = payload;
    },
};

export default mutations;

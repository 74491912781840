import { IUser } from "../user/user.models";

export function parseJwt(bearerToken: string): IUser {
  if (!bearerToken) return { _id: "", login: false };
  const base64Url = bearerToken.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}

import { DefineMutations, DefineTypes } from "@/app/store/store.helper";
import { RootState } from "@/app/store/root.models";
import { AgentState, IAgent } from "./agent.models";
import {
  Conversation,
  ConversationMessage,
  ConversationNewEntryQueue,
  Interaction,
} from "@/app/store/modules/conversation/conversation.models";

export interface AgentMutations {
  setAgent: AgentState["agent"];
  setLoading: AgentState["loading"];
  resetAgent: undefined;
  setAgentOpenConversations: AgentState["agentOpenConversations"];
  setAgentOpenTotalConversations: AgentState["agentOpenTotalConversations"];
  setAgentCloseConversations: AgentState["agentCloseConversations"];
  setAgenCloseTotalConversations: AgentState["agentCloseTotalConversations"];
  setQueue: AgentState["agentQueue"];
  resetQueue: AgentState["agentQueue"];
  setQueueTotal: AgentState["agentQueueTotal"];
  setNewEntryInQueue: ConversationNewEntryQueue;
  setRemoveEntryOfQueue: ConversationNewEntryQueue;
  removeConversationOpen: string;
  removeConversationClosed: string;
  setMessageInOpenConversation: ConversationMessage;
  setCurrentConversationInConversations: Conversation;
  reSetAgentCloseConversations: string;
  setInteractions: AgentState["interactions"];
  setNewInteraction: Interaction;
  setUpdateInteraction: Interaction;
  setActualPage: number;
  setTotalPages: number;
  loadMoreInteractions: Interaction[];
  setAgentTransferredConversation: { agent: any; conversation: Conversation };
}

export const agentMutationsTypes: DefineTypes<AgentMutations> = {
  setAgent: (payload) => ({ type: "setAgent", payload }),
  setLoading: (payload) => ({ type: "setLoading", payload }),
  resetAgent: () => ({ type: "resetAgent" }),
  loadMoreInteractions: (payload) => ({
    type: "loadMoreInteractions",
    payload,
  }),
  setActualPage: (payload) => ({ type: "setActualPage", payload }),
  setTotalPages: (payload) => ({ type: "setTotalPages", payload }),
  setAgentTransferredConversation: (payload) => ({
    type: "setAgentTransferredConversation",
    payload,
  }),
  setAgentOpenConversations: (payload) => ({
    type: "setAgentOpenConversations",
    payload,
  }),
  setAgentOpenTotalConversations: (payload) => ({
    type: "setAgentOpenTotalConversations",
    payload,
  }),
  setAgentCloseConversations: (payload) => ({
    type: "setAgentCloseConversations",
    payload,
  }),
  setAgenCloseTotalConversations: (payload) => ({
    type: "setAgenCloseTotalConversations",
    payload,
  }),
  setQueue: (payload) => ({
    type: "setQueue",
    payload,
  }),
  resetQueue: (payload) => ({
    type: "resetQueue",
    payload,
  }),
  setQueueTotal: (payload) => ({
    type: "setQueueTotal",
    payload,
  }),
  setNewEntryInQueue: (payload) => ({
    type: "setNewEntryInQueue",
    payload,
  }),
  setRemoveEntryOfQueue: (payload) => ({
    type: "setRemoveEntryOfQueue",
    payload,
  }),
  setMessageInOpenConversation: (payload) => ({
    type: "setMessageInOpenConversation",
    payload,
  }),
  setCurrentConversationInConversations: (payload) => ({
    type: "setCurrentConversationInConversations",
    payload,
  }),
  removeConversationOpen: (payload) => ({
    type: "removeConversationOpen",
    payload,
  }),
  removeConversationClosed: (payload) => ({
    type: "removeConversationOpen",
    payload,
  }),
  reSetAgentCloseConversations: (payload) => ({
    type: "reSetAgentCloseConversations",
    payload,
  }),
  setInteractions: (payload) => ({
    type: "setInteractions",
    payload,
  }),
  setNewInteraction: (payload) => ({
    type: "setNewInteraction",
    payload,
  }),

  setUpdateInteraction: (payload) => ({
    type: "setUpdateInteraction",
    payload,
  }),
};

const mutations: DefineMutations<AgentMutations, AgentState, RootState> = {
  setAgent(state, { payload }) {
    state.agent = payload;
  },
  setLoading(state, { payload }) {
    state.loading = payload;
  },
  setAgentTransferredConversation(state, { payload: transferred }) {
    state.transferredToAgent = transferred;
    setTimeout(() => (state.transferredToAgent = null), 1000);
  },
  resetAgent(state) {
    state.agent = <IAgent>{};
    state.agentSockets = [];
    state.agentQueue = [];
    state.agentOpenConversations = [];
    state.agentCloseConversations = [];
  },
  setAgentOpenConversations(state, { payload: conversations }) {
    const newConversations = conversations.map((conversation) => {
      const { currentInputInteraction } = conversation;
      if (Array.isArray(currentInputInteraction)) {
        conversation.newMessage = conversation.currentInputInteraction.length;
        conversation.currentInputInteraction = [];
      }
      return conversation;
    });
    state.agentOpenConversations =
      state.agentOpenConversations.concat(newConversations);
  },
  setAgentOpenTotalConversations(state, { payload: total }) {
    state.agentOpenTotalConversations = total;
  },

  setAgentCloseConversations(state, { payload: conversations }) {
    const newConversations = conversations.map((conversation) => {
      const { currentInputInteraction } = conversation;
      if (Array.isArray(currentInputInteraction)) {
        conversation.newMessage = conversation.currentInputInteraction.length;
        conversation.currentInputInteraction = [];
      }
      return { ...conversation, isClosed: true };
    });
    state.agentCloseConversations =
      state.agentCloseConversations.concat(newConversations);
  },
  setAgenCloseTotalConversations(state, { payload }) {
    state.agentCloseTotalConversations = payload;
  },

  reSetAgentCloseConversations(state: any) {
    state.agentCloseConversations = [];
    state.agentOpenConversations = [];
  },
  setQueue(state, { payload }) {
    const queue: ConversationNewEntryQueue[] = [];
    payload.reverse().forEach((conversation) => {
      /*if (
                !queue.find(
                    (tempConversation) => tempConversation.consumer === conversation.consumer
                )
            ) {
                queue.push(conversation);
            }*/

      queue.push(conversation);
    });

    // console.log('queue', queue);
    state.agentQueue.push(...payload);
  },
  resetQueue(state, { payload }) {
    // console.log('queue', queue);
    state.agentQueue = payload;
  },
  setQueueTotal(state, { payload }) {
    state.agentQueueTotal = payload;
  },
  setNewEntryInQueue(state, { payload: item }) {
    const queue = state.agentQueue;
    if (state.agentQueue.length < state.agentQueuePerPage) {
      const existsOneEntry = queue.find(
        (queueItem) => queueItem.consumer === item.consumer
      );
      if (!existsOneEntry) {
        state.agentQueue.push(item);
      } else {
        state.agentQueue.splice(state.agentQueue.indexOf(existsOneEntry), 1);
        state.agentQueue.push(item);
      }
    }
  },
  setRemoveEntryOfQueue(state, { payload: item }) {
    const queue = state.agentQueue;
    if (Array.isArray(queue) && item._id) {
      const existsOneEntry = queue.findIndex((queueItem) => {
        if (queueItem.conversation) {
          return queueItem.conversation._id === item._id;
        } else {
          return false;
        }
      });
      if (existsOneEntry === 0 || existsOneEntry) {
        state.agentQueue.splice(existsOneEntry, 1);
      }
    }
  },
  setMessageInOpenConversation(state, { payload: newMessage }) {
    state.agentOpenConversations = state.agentOpenConversations.map(
      (conversation) => {
        if (conversation._id !== newMessage.conversation) return conversation;
        const interactions = conversation.interactions;
        if (Array.isArray(interactions)) {
          const lastInteraction = interactions[interactions.length - 1];
          if (lastInteraction.output.length) {
            interactions.push({
              conversation: newMessage.conversation,
              input: newMessage.input,
              output: [],
            });
          } else {
            lastInteraction.input = newMessage.input;
          }
        }
        return {
          ...conversation,
          newMessage: !conversation.newMessage
            ? 1
            : conversation.newMessage + 1,
          newinteractions: (newMessage as any).newinteractions,
          interactions,
        };
      }
    );
  },
  setCurrentConversationInConversations(state, { payload: conversation }) {
    if (conversation && conversation._id) {
      state.agentOpenConversations = state.agentOpenConversations.map(
        (agentConversation) => {
          if (agentConversation._id === conversation._id) {
            return {
              ...conversation,
            };
          } else {
            return agentConversation;
          }
        }
      );
      if (conversation.isClosed) {
        state.agentCloseConversations = state.agentCloseConversations.map(
          (agentConversation) => {
            if (agentConversation._id === conversation._id) {
              return {
                ...conversation,
              };
            } else {
              return agentConversation;
            }
          }
        );
      }
    }
  },
  removeConversationOpen(state, { payload: id }) {
    state.agentOpenConversations = state.agentOpenConversations.filter(
      (conversation) => conversation._id !== id
    );
  },
  removeConversationClosed(state, { payload: id }) {
    state.agentCloseConversations = state.agentCloseConversations.filter(
      (conversation) => conversation._id !== id
    );
  },
  setInteractions(state, { payload: interactions }) {
    state.interactions = interactions;
  },
  setNewInteraction(state, { payload: interaction }) {
    state.interactions.push(interaction);
  },
  setActualPage(state, { payload: actualPage }) {
    state.actualPage = actualPage;
  },
  setTotalPages(state, { payload: totalPages }) {
    state.totalPages = totalPages;
  },
  loadMoreInteractions(state, { payload: interactions }) {
    state.interactions = state.interactions
      .reverse()
      .concat(interactions)
      .reverse();
  },
  setUpdateInteraction(state, { payload: interaction }) {
    let index = state.interactions.findIndex((e) => e._id == interaction._id);

    if (index > -1) {
      let temPInteraction = state.interactions[index];
      state.interactions.splice(index, 1);
      state.interactions[index] = { ...temPInteraction, ...interaction };
    }
  },
};

export default mutations;

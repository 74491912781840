import {
  Conversation,
  ConversationNewEntryQueue,
  Interaction,
} from "@/app/store/modules/conversation/conversation.models";
import Socket = SocketIOClient.Socket;

export interface AgentState {
  agent: IAgent;
  loading: boolean;
  agentOpenConversations: Conversation[];
  agentOpenTotalConversations: number;
  agentCloseConversations: Conversation[];
  agentCloseTotalConversations: number;
  agentQueue: ConversationNewEntryQueue[];
  agentQueueTotal: number;
  agentQueuePerPage: number;
  agentSockets: any[];
  interactions: Interaction[];
  socketInstances: Socket[];
  actualPage: number;
  totalPages: number;
  transferredToAgent: { agent: any; conversation: Conversation } | null;
}

export const SOCKET_EVENTS = {
  NEW_ENTRY: "NEW_ENTRY",
  REMOVE_ENTRY: "REMOVE_ENTRY",
  AGENT: "AGENT",
  NEW_MESSAGE: "NEW_MESSAGE",
  DELETE_CONVERSATION: "DELETE_CONVERSATION",
  DUPLICATED_SESSION: "DUPLICATED_SESSION",
  AWATING_RESPONSE: "AWATING_RESPONSE",
  TRANSFERRED_CONVERSATION: "TRANSFERRED_CONVERSATION",
  UPDATE_INTERACTION: "UPDATE_INTERACTION",
};

export interface IAgent {
  _id: string;
  subscriptions: string[];
}

export type ExtendedAgentState = { agent?: AgentState };

export const initialAgentState: AgentState = {
  agent: { _id: "", subscriptions: [] },
  loading: false,
  agentOpenConversations: [],
  agentOpenTotalConversations: 0,
  agentCloseConversations: [],
  agentCloseTotalConversations: 0,
  agentQueue: [],
  agentQueueTotal: 0,
  agentQueuePerPage: 10,
  agentSockets: [],
  interactions: [],
  socketInstances: [],
  actualPage: 0,
  totalPages: 0,
  transferredToAgent: null,
};

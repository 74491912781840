import { DefineTypes, DefineActions } from "@/app/store/store.helper";
import { RootState } from "@/app/store/root.models";
import Axios, { AxiosError, AxiosResponse } from "axios";
import { BASE_URL_ORCHESTATOR } from "@/config";
import { agentMutationsTypes } from "../agent/agent.mutations";
import {
  ConversationState,
  Conversation,
  ConversationNewEntryQueue,
  ConversationMessageElement,
  Interaction,
  returnOldConversation,
  BusinessStatus,
  ContactData,
} from "./conversation.models";
import { conversationMutationsTypes } from "./conversation.mutations";
import { store, storeTypes } from "@/app/store";
const _ = require("lodash");

export interface AgentActions {
  closeConversation: undefined;
  grebConversation: ConversationNewEntryQueue;
  sendMessage: ConversationMessageElement;
  returnConversation: returnOldConversation;
  shareSendMessage: ConversationMessageElement;
  changeConversationStatus: BusinessStatus;
  changeCurrentConversationContactData: ContactData;
  transferConversationToAgent: string;
  transferConversationToSegment: string;
}

export const conversationActionsTypes: DefineTypes<AgentActions> = {
  closeConversation: (payload) => ({
    type: "closeConversation",
    payload,
  }),
  changeConversationStatus: (payload) => ({
    type: "changeConversationStatus",
    payload,
  }),
  grebConversation: (payload) => ({
    type: "grebConversation",
    payload,
  }),
  sendMessage: (payload) => ({
    type: "sendMessage",
    payload,
  }),
  returnConversation: (payload) => ({
    type: "returnConversation",
    payload,
  }),
  shareSendMessage: (payload) => ({
    type: "shareSendMessage",
    payload,
  }),
  transferConversationToAgent: (payload) => ({
    type: "transferConversationToAgent",
    payload,
  }),
  transferConversationToSegment: (payload) => ({
    type: "transferConversationToSegment",
    payload,
  }),
  changeCurrentConversationContactData: (payload) => ({
    type: "changeCurrentConversationContactData",
    payload,
  }),
};

const actions: DefineActions<AgentActions, ConversationState, RootState> = {
  async closeConversation({ commit, state, rootState }) {
    if (
      state.currentConversation &&
      state.currentConversation._id &&
      rootState.agent!.agent &&
      rootState.agent!.agent._id
    ) {
      // commit(conversationMutationsTypes.setLoading(true));
      const response = await Axios(
        `${BASE_URL_ORCHESTATOR}/agent/${
          rootState.agent!.agent._id
        }/conversation/close/${state.currentConversation._id}`
      );
      if (response != undefined) {
        const { success } = response!.data;
        if (success) {
          commit(
            agentMutationsTypes.setAgentCloseConversations([
              { ...state.currentConversation, isClosed: true },
            ])
          );
          commit(
            agentMutationsTypes.removeConversationOpen(
              state.currentConversation._id
            )
          );
          commit(
            agentMutationsTypes.setCurrentConversationInConversations(
              <Conversation>{}
            )
          );
          commit(
            conversationMutationsTypes.setCurrentConversation(<Conversation>{})
          );

          //TODO: MOSTRAR ALERTA ON SUCCESS
        }
      }
      //TODO: MOSTAR ALERTA DE ERROR SI FALLA

      // commit(conversationMutationsTypes.setLoading(false));
    }
  },
  transferConversationToAgent(
    { commit, rootState, state },
    { payload: agentId }
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await Axios.post(
        `${BASE_URL_ORCHESTATOR}/agent/${
          rootState.agent!.agent._id
        }/conversation/transferToAgent/${
          rootState.conversation!.currentConversation._id
        }`,
        { toTransferAgent: agentId }
      )
        .then((response) => {
          commit(
            agentMutationsTypes.removeConversationOpen(
              state.currentConversation._id
            )
          );
          commit(
            conversationMutationsTypes.setCurrentConversation(<Conversation>{})
          );
          commit(
            agentMutationsTypes.setCurrentConversationInConversations(
              <Conversation>{}
            )
          );
          resolve(response);
        })
        .catch(reject);
    });
  },
  transferConversationToSegment(
    { commit, rootState, state },
    { payload: segmentId }
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await Axios.post(
        `${BASE_URL_ORCHESTATOR}/agent/${
          rootState.agent!.agent._id
        }/conversation/transferToSegment/${
          rootState.conversation!.currentConversation._id
        }`,
        { toTransferSegment: segmentId }
      )
        .then((response) => {
          commit(
            agentMutationsTypes.removeConversationOpen(
              state.currentConversation._id
            )
          );
          commit(
            conversationMutationsTypes.setCurrentConversation(<Conversation>{})
          );
          commit(
            agentMutationsTypes.setCurrentConversationInConversations(
              <Conversation>{}
            )
          );
          resolve(response);
        })
        .catch(reject);
    });
  },
  changeConversationStatus({ commit, state }, { payload: status }) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${BASE_URL_ORCHESTATOR}/conversation/changeStatus/${state.currentConversation._id}`,
        {
          status: status.name,
        }
      )
        .then((response) => {
          if (response) {
            commit(
              conversationMutationsTypes.setCurrentConversation(
                response.data.conversation
              )
            );
            resolve(true);
          } else {
            reject({ message: "Ocurrio un error, no hay response" });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Este metodo se cominica con el orquestados y  obtiene el historico de la conversacion
   * @param param0
   * @param param1
   */
  async grebConversation({ commit, rootState }, { payload }) {
    let iteem: any = payload;
    let id = "";
    id = iteem._id;

    //  commit(conversationMutationsTypes.setLoading(true));
    commit(conversationMutationsTypes.setCurrentConversation(iteem));
    return new Promise((resolve, reject) => {
      Axios(
        `${BASE_URL_ORCHESTATOR}/agent/${
          rootState.agent!.agent._id
        }/conversation/grab/${id}`
      )
        .then(function (response: any) {
          const { conversation } = response.data;

          commit(agentMutationsTypes.setAgentOpenConversations([conversation]));
          resolve(response);
        })
        .catch(function (error: any) {
          iteem._id = iteem.conversation._id;
          commit(agentMutationsTypes.setRemoveEntryOfQueue(iteem));
          reject(error);
        })
        .finally(function () {
          commit(conversationMutationsTypes.setLoading(false));
        });
    });
  },
  /**
   * Se ejecuta cuando el agente envia un mensaje, se comunica con el orquesatdor para procesar el envio del mensaje
   * @param param0
   * @param param1
   */
  async sendMessage({ commit, state, rootState }, { payload: message }) {
    console.log("sending message");
    if (
      (store.state.conversation!.currentConversation.newinteractions as any) > 0
    ) {
      commit(
        storeTypes.conversation.mutations.setCurrentConversationNewInteractions(
          0
        )
      );
    }

    console.log("state.currentConversation", state.currentConversation);
    const response = await Axios.post(
      `${BASE_URL_ORCHESTATOR}/conversation/${
        state.currentConversation._id
      }/agent/${rootState.agent!.agent._id}/response`,
      message
    );

    const interaction: Interaction = response.data.interaction;
    await new Promise((resolve) => {
      setTimeout(() => {
        if (
          store.state.agent!.interactions[
            store.state.agent!.interactions.length - 1
          ]._id !== interaction._id
        ) {
          commit(agentMutationsTypes.setNewInteraction(interaction));
        }
        resolve(true);
      }, Math.floor(Math.random() * 1500));
    });
    console.log("response.status", response.status);
    return response.status;
  },
  returnConversation({ commit, rootState }, { payload: conversationToReturn }) {
    return new Promise(async (resolve, reject) => {
      commit(conversationMutationsTypes.setLoading(true));
      await Axios(
        `${BASE_URL_ORCHESTATOR}/agent/${
          rootState.agent!.agent._id
        }/conversation/returnConversation/${conversationToReturn}`
      )
        .then((res: AxiosResponse) => {
          const { openConversations } = res.data;
          resolve(openConversations[0]);
        })
        .catch((err: AxiosError) => {
          reject(err);
        })
        .finally(() => {
          commit(conversationMutationsTypes.setLoading(false));
        });
    });
  },

  /**
   * Se ejecuta cuando el agente comparte un mensaje, se comunica con el orquestador para procesar el envio de varios mensajes
   * @param param0
   * @param param1
   */
  async shareSendMessage({ commit, rootState }, { payload: content }) {
    if (
      (store.state.conversation!.currentConversation.newinteractions as any) > 0
    ) {
      commit(
        storeTypes.conversation.mutations.setCurrentConversationNewInteractions(
          0
        )
      );
    }

    const response = await Axios.post(
      `${BASE_URL_ORCHESTATOR}/conversation/${content.id}/agent/${
        rootState.agent!.agent._id
      }/response`,
      content
    );

    if (content.id === rootState.conversation!.currentConversation._id) {
      const interaction: Interaction = response.data.interaction;
      commit(agentMutationsTypes.setNewInteraction(interaction));
      await new Promise((resolve) => {
        setTimeout(() => {
          commit(storeTypes.conversation.mutations.setOutputMessage(content));
          resolve(true);
        }, Math.floor(Math.random() * 1500));
      });
    }
    return response.status;
  },
  changeCurrentConversationContactData(
    { commit, state },
    { payload: contactData }
  ) {
    const currentConversation = _.cloneDeep(state.currentConversation);
    currentConversation.contactData = contactData;
    commit(
      conversationMutationsTypes.setCurrentConversation(currentConversation)
    );
  },
};

export default actions;

import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faBookmark ,faTrash, faPaperPlane, faPowerOff, faHome, faUser, faPhone, faProjectDiagram, faArchive, faUserCircle, faSpinner,
    faCircleNotch, faTimes, faCog, faSignature, faAddressCard, faCity, faEnvelope, faSave, faPen, faSync,
    faIdCard, faMapMarker, faMailBulk, faDownload, faRedo, faUndo, faPlusCircle, faSearch, faExclamationTriangle, faPaperclip,
    faMicrophone, faSmile, faChevronRight, faReply, faCheck, faCheckDouble, faEllipsisH, faCameraRetro, faShareSquare, faShare, faComments, faInbox,
    faFileWord, faFileExcel, faFilePdf, faFileAlt, faAlignLeft, faComment, faStream, faArrowLeft, faBell
} from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";

Vue.component('font-awesome-icon', FontAwesomeIcon);

library.add(faTrash,faBookmark, faPaperPlane, faWhatsapp, faFacebookMessenger, faPowerOff, faHome, faUser, faPhone, faProjectDiagram, faArchive,
    faUserCircle, faSpinner, faCircleNotch, faTimes, faCog, faSignature, faAddressCard, faCity, faEnvelope, faSave, faPen, faSync,
    faIdCard, faMapMarker, faPlusCircle, faMailBulk, faDownload, faRedo, faUndo, faSearch, faExclamationTriangle, faPaperclip, faMicrophone,
    faSmile, faChevronRight, faReply, faCheck, faCheckDouble, faEllipsisH, faCameraRetro, faShareSquare, faShare, faComments, faInbox, faFileWord,
    faFileExcel, faFilePdf, faFileAlt, faAlignLeft, faComment, faStream, faArrowLeft, faBell);

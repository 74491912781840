import Vue from "vue";
import VueRouter from "vue-router";
import RegisterForm from "../components/register-form/register-form";
import { store } from "./../store";
// Components
const AppLogin = () => import("../views/app-login/AppLogin.vue");
const AppRegister = () => import("../views/app-register/AppRegister.vue");
const AgentChat = () => import("../views/agent-chat/AgentChat.vue");

const AgentsManagement = () =>
  import("../views/administrator/agents-management/AgentsManagement.vue");
const AdminsManagement = () =>
  import("../views/administrator/admins-management/AdminsManagement.vue");
const AdministratorDashboard = () =>
  import(
    "@/app/views/administrator/administrator-dashboard/AdministratorDashboard.vue"
  );
const ProjectsManagement = () =>
  import("../views/administrator/projects-management/ProjectsManagement.vue");
const FlowsManagement = () =>
  import("@/app/views/administrator/flows-management/FlowsManagement.vue");
const ConversationsManagement = () =>
  import(
    "@/app/views/administrator/conversations-management/ConversationsManagement.vue"
  );
const BussinessStates = () =>
  import(
    "@/app/views/administrator/bussiness-states-management/BussinessStatesManagement.vue"
  );
const CompaniesManagement = () =>
  import("../views/owner/companies-management/CompaniesManagement.vue");
Vue.use(VueRouter);

const routes = [
  {
    path: "*",

    component: AgentChat,
    meta: {
      requiresAuth: true,
    },
    /*  redirect: {
        name: "agent"
      }*/
  },
  {
    path: "/",
    name: "index",
    component: AgentChat,
    meta: {
      requiresAuth: true,
    },
    /*  redirect: {
        name: "agent"
      }*/
  },
  {
    name: "login",
    path: "/login",
    component: AppLogin,
    meta: {
      reload: true,
    },
  },
  {
    name: "register",
    path: "/register",
    component: AppRegister,
    meta: {
      reload: true,
    },
  },
  {
    path: "/flow",
    name: "flow",
    component: FlowsManagement,
  },

  {
    path: "/agent",
    name: "agent",
    component: AgentChat,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin",
    name: "admin",
    component: AdministratorDashboard,
    meta: {
      requiresAuth: true,
      reload: true,
    },
  },
  {
    path: "/states",
    name: "states",
    component: BussinessStates,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/agents",
    name: "agents",
    component: AgentsManagement,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admins",
    name: "admins",
    component: AdminsManagement,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projects",
    name: "projects",
    component: ProjectsManagement,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/companies",
    name: "companies",
    component: CompaniesManagement,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/conversations",
    name: "conversations",
    component: ConversationsManagement,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({ mode: "history", routes });

router.beforeEach((to, from, next) => {
  const { user: userState } = store.state;
  const user = userState!.user;
  if (!user && to.name !== "login" && to.name !== "register") {
    next({ name: "login" });
    return;
  } else {
    next();
  }
});

export default router;

export interface UserState {
    user: IUser;
    loading: boolean;
}

export enum UserRole {
    AGENT = "AGENT",
    ADMIN = "ADMIN",
    BASE = "BASE",
}

export interface IUser {
    _id: string;
    login: boolean;
    role?: UserRole;
    name?: {
        firstName: string;
        lastName: string;
    };
    exp?: any;
    company?: any;
    password?: any;
}

export type ExtendedUserState = { user?: UserState };

export const initialUserState: UserState = {
    user: { _id: "", login: false },
    loading: false,
};
